<template>
    <div>
        <!--------------------面包屑导航-------------------->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item >首页</el-breadcrumb-item>
            <el-breadcrumb-item>基础设置</el-breadcrumb-item>
            <el-breadcrumb-item>区域维护</el-breadcrumb-item>
        </el-breadcrumb>
        <!----------------------卡片区域---------------------->
        <el-card>
            <el-table :data="list" border v-loading="loading" element-loading-text="加载中,请稍候" :header-cell-style="{background:'#FAFAFA'}"><!--stripe带斑马纹的表格 border边框-->
                <el-table-column type="index" label="序号" width="60px">
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 + query.number * (query.page - 1) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="id" label="地区编码"></el-table-column>
                <el-table-column prop="parent_name" label="上级名称"></el-table-column>
                <el-table-column prop="name" label="地区名称"></el-table-column>
            </el-table>
            <!--------------------分页组件--------------------->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="query.page" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="query.number"
                           layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [],//表格列表
            total: 0,//总条数
            query: {
                page: 1,//页码
                number: 10,//数量
            },
            loading: false,//表格加载loading
        }
    },
    mounted () {
        this.getList()//查列表
    },
    methods: {
        //查列表
        async getList() {
            this.loading = true;
            let data = {
                page: this.query.page,
                number: this.query.number,
            }
            let { data: res } = await this.$http.get('/admin/api/area/pageList', { params: data})
            this.loading = false;
            if (res.code == 200) {
                if(res.data.result) {
                    this.list = res.data.result
                    this.total = res.data.total
                }
            } else {
                this.$message.error(res.message)
            }
        },
        // 监听每页显示多少条的改变
        handleSizeChange (newSize) {
            this.query.page = 1
            this.query.number = newSize
            this.getList()
        },
        // 监听选择第了几页
        handleCurrentChange (newPage) {
            this.query.page = newPage
            this.getList()
        },
    }
}
</script>

<style scoped>

</style>
